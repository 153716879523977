import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import rootReducer, { RootState } from './Reducers';

// Persist configuration
const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['studentHistory', 'feedbackForm', 'assessment', 'workflow'], // These states are not encrytped
};

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [ 
          'persist/PERSIST',
          'persist/REHYDRATE',
          'persist/PAUSE',
          'persist/FLUSH',
          'persist/PURGE',
          'persist/REGISTER',
        ],
      },
    }),
  devTools: process.env.REACT_APP_ENV !== 'production', // Enable Redux DevTools only in development
});

export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
export default store;
