import React, { useRef, useState, useEffect } from 'react';
import { SpacerDiv, MobileModal, FingerprintBtn } from 'Components';
import { usePreventScroll } from 'CustomHooks';

import '../Styles/mobileSignature.scss';

interface SignatureProps {
  showCanvas?: boolean;
  onSave: (signatureDataUrl: string) => void;
}

const Signature: React.FC<SignatureProps> = ({ onSave, showCanvas }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [modalOpen, setModalOpen] = useState(showCanvas ?? false);
  const [signature, setSignature] = useState<string>('');
  const [hasDrawn, setHasDrawn] = useState(false);
  const [canvasDimensions, setCanvasDimensions] = useState({ width: 0, height: 0 });

  const isControlled = showCanvas !== undefined;
  const effectiveOpen = isControlled ? showCanvas : modalOpen;

  const updateCanvasDimensions = () => {

    if (canvasRef.current?.parentNode) {
      const parent = canvasRef.current.parentNode as HTMLElement;
      const parentWidth = parent.clientWidth;
      const parentHeight = parent.clientHeight;
      const width = parentWidth * 0.95;
      const height = parentHeight * 0.65;

      if (canvasRef.current) {
        canvasRef.current.width = width;
        canvasRef.current.height = height;
      }

      setCanvasDimensions({ width, height });
    }
  };

  const drawPlaceholder = () => {
    if (canvasRef.current && canvasDimensions.width > 0 && canvasDimensions.height > 0) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        context.clearRect(0, 0, canvasDimensions.width, canvasDimensions.height);

        // Draw Bezier curve
        context.strokeStyle = '#d0d0d0';
        context.lineWidth = 4;
        context.beginPath();
        context.moveTo(
          canvasDimensions.width * 0.15,
          canvasDimensions.height * 0.75
        );
        context.bezierCurveTo(
          canvasDimensions.width * 0.3,
          canvasDimensions.height * 0.9,
          canvasDimensions.width * 0.7,
          canvasDimensions.height * 0.7,
          canvasDimensions.width * 0.8,
          canvasDimensions.height * 0.8
        );
        context.stroke();
        context.closePath();

        // Draw "Sign here" text
        document.fonts.load('32px "Pacifico"').then(() => {
          context.save();
          context.translate(
            canvasDimensions.width * 0.16,
            canvasDimensions.height * 0.7
          );
          context.font = 'italic 60px "Pacifico", cursive';
          context.fillStyle = '#d0d0d0';
          context.fillText('Sign here', 0, 0);
          context.restore();
        });
      }
    }
  };

  usePreventScroll(effectiveOpen);

  useEffect(() => {
    if (effectiveOpen) {
      setTimeout(() => {
        updateCanvasDimensions();
        drawPlaceholder();
      }, 50);
    }
  }, [effectiveOpen]);

  useEffect(() => {
    // Ensure placeholder is drawn on the initial render when canvas dimensions are set
    if (canvasDimensions.width > 0 && canvasDimensions.height > 0) {
      drawPlaceholder();
    }
  }, [canvasDimensions]);

  const startDrawing = (event: React.MouseEvent | React.TouchEvent) => {
    if (canvasRef.current && !hasDrawn) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        context.clearRect(0, 0, canvasDimensions.width, canvasDimensions.height);
        context.beginPath();
      }
    }
    setIsDrawing(true);
    setHasDrawn(true);
    draw(event);
  };

  const endDrawing = () => {
    setIsDrawing(false);
    if (canvasRef.current) {
      canvasRef.current.getContext('2d')?.beginPath();
    }
  };

  const draw = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDrawing || !canvasRef.current) return;

    const context = canvasRef.current.getContext('2d');
    if (!context) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;

    context.lineWidth = 4;
    context.lineCap = 'round';
    context.strokeStyle = '#000000';

    context.lineTo(clientX - rect.left, clientY - rect.top);
    context.stroke();
    context.beginPath();
    context.moveTo(clientX - rect.left, clientY - rect.top);
  };

  const clearCanvas = () => {
    if (canvasRef.current) {
      const context = canvasRef.current.getContext('2d', { willReadFrequently: true });
      if (!context) return;

      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      setHasDrawn(false);

      drawPlaceholder();
    }
  };

  const trimWhitespaceFromImage = (imageSrc: string, callback: (trimmedImage: string) => void) => {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
  
      canvas.width = image.width;
      canvas.height = image.height;
      context?.drawImage(image, 0, 0);
  
      const imageData = context?.getImageData(0, 0, canvas.width, canvas.height);
      if (!imageData) return;
  
      const { data, width, height } = imageData;
  
      let top = height, bottom = 0, left = width, right = 0;
  
      for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
          const index = (y * width + x) * 4;
          const alpha = data[index + 3]; // Alpha channel
  
          if (alpha > 0) {
            if (y < top) top = y;
            if (y > bottom) bottom = y;
            if (x < left) left = x;
            if (x > right) right = x;
          }
        }
      }
  
      const croppedWidth = right - left + 1;
      const croppedHeight = bottom - top + 1;
  
      const trimmedCanvas = document.createElement('canvas');
      trimmedCanvas.width = croppedWidth;
      trimmedCanvas.height = croppedHeight;
  
      const trimmedContext = trimmedCanvas.getContext('2d');
      trimmedContext?.drawImage(canvas, left, top, croppedWidth, croppedHeight, 0, 0, croppedWidth, croppedHeight);
  
      const trimmedImage = trimmedCanvas.toDataURL('image/png');
      callback(trimmedImage);
    };
  };

  const handleSaveClick = () => {
    if (canvasRef.current) {
      const signatureDataUrl = canvasRef.current.toDataURL();
      trimWhitespaceFromImage(signatureDataUrl, (trimmedImage) => {
        setSignature(trimmedImage);
        if (!isControlled) setModalOpen(false);
        onSave(trimmedImage);
      });
    }
  };

  return (
    <div>
      <div onClick={() => !isControlled && setModalOpen(true)}>

        {signature ? (
          <img
            className="signature-preview"
            src={signature}
            alt="User's signature"
          />
        ) : (
          <FingerprintBtn label="touch to sign"/>
        )}
      </div>

      <MobileModal
        className="mobile-signature-modal"
        isOpen={effectiveOpen || false}
        onClose={() => (isControlled ? setModalOpen(false) : setModalOpen(false))}
        withButton={false}
      >
        <canvas
          ref={canvasRef}
          width={canvasDimensions.width}
          height={canvasDimensions.height}
          className="mobile-signature-canvas"
          onMouseDown={startDrawing}
          onMouseUp={endDrawing}
          onMouseMove={draw}
          onTouchStart={startDrawing}
          onTouchEnd={endDrawing}
          onTouchMove={draw}
        />
        <div className="flex-container">
          <button
            className="btn-primary"
            style={{ width: '150px', alignSelf: 'flex-start' }}
            onClick={clearCanvas}
          >
            CLEAR
          </button>
          <button
            className="btn-primary"
            style={{ width: '150px', marginBottom: '5px' }}
            onClick={handleSaveClick}
            disabled={!hasDrawn}
          >
            SAVE
          </button>
        </div>
        <SpacerDiv rem={1} />
        <p style={{ display: hasDrawn ? 'none' : 'block' }}>Please sign</p>
      </MobileModal>
    </div>
  );
};

export default Signature;
