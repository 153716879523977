import React, {useEffect, useRef, useState, forwardRef, useImperativeHandle} from 'react';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf';
import SignatureCanvas from 'react-signature-canvas';
import dayjs from 'dayjs';
import { jsPDF } from 'jspdf';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pdfWorker from 'pdfjs-dist/build/pdf.worker.entry';
import useFetchApi from "Services/FetchApi";
import coverSheet from 'Documents/CoverSheet.pdf';
import useFetchPdf from "Services/FetchPdf";
import {useUserDeets, usePreventScroll } from '../CustomHooks'

// Set the worker source to the public directory
// GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;
// GlobalWorkerOptions.workerSrc = 'pdf.worker.js';
GlobalWorkerOptions.workerSrc = pdfWorker;

// const A4_WIDTH = 210;  // mm
// const A4_HEIGHT = 297; // mm

const SignaturePanel = forwardRef(({ annotations, studentId, modId, modCode, certCode, modName, apprenticeModsId }, ref) => {
    // console.log('annotations: ', annotations)
    // console.log('modCode: ', modCode)
    const user = useUserDeets();
    const fetchPdf = useFetchPdf();
    const fetchApi = useFetchApi();
    const pdfCanvasRef = useRef(null);
    const sigCanvasRef = useRef({});
    const renderTaskRef = useRef(null);
    const [signature, setSignature] = useState(null);
    const [timestamp, setTimestamp] = useState(null);
    const inputRefs = useRef([]);
    const [initialPdfCanvasDimensions, setInitialPdfCanvasDimensions] = useState({ width: 0, height: 0 });
    const pdfCanvasHeight = pdfCanvasRef.current ? pdfCanvasRef.current.height : 1;
    const pdfCanvasWidth = pdfCanvasRef.current ? pdfCanvasRef.current.width : 1;
    // const [isDrawingEnabled, setIsDrawingEnabled] = useState(window.innerWidth > 900); // State for drawing canvas visibility
    usePreventScroll(true);

    const [dates, setDates] = useState(() =>
        annotations.reduce((acc, annotation) => {
          if (
            annotation.input === "date" &&
            annotation.date != null &&
            annotation.name
          ) {
            try {
              // Parse date and ensure it's a valid Date object
              const parsedDate = new Date(annotation.date);
              if (!isNaN(parsedDate)) {
                acc[annotation.name] = parsedDate; // Use the `name` as the key
              } else {
                console.error(`Invalid date for annotation: ${annotation.date}`);
              }
            } catch (error) {
              console.error(`Error parsing date: ${annotation.date}`, error);
            }
          }
          return acc;
        }, {})
      );

    const [inputValues, setInputValues] = useState(
        annotations.reduce((acc, annotation) => {
            if (annotation.input) {
                acc[annotation.name] = annotation.value || ''; // Initialize with the current value or empty string
            }
            if (annotation.name === "Name") {
                annotation.value = user.fullName || ""; // Set the user full name if available
            }
            if (annotation.name === "ModCode") {
                annotation.label = modCode || ""; // Set the user full name if available
            }
            if (annotation.name === "ModName") {
                annotation.label = modName || ""; // Set the user full name if available
            }
            if (annotation.name === "DOB") {
                annotation.date = user.dob || "Select Date"; // Set the user full name if available
            }
            return acc;
        }, {})
    );

    useEffect(() => {
        updatePenWidths();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const calculateFontSize = (canvasHeight, dpr) => {
        const baseFontSize = (canvasHeight * 0.014) / dpr; // Halve the base font size calculation and normalize by DPR
        return baseFontSize;
    };

    const calculatePenWidths = (canvasHeight, isMobile) => {
        const baseMinWidth = 0.25; // Base minimum pen width
        const baseMaxWidth = 1; // Base maximum pen width
        const scaleFactor = canvasHeight / 1000; // Adjust the denominator to your base canvas height
        const mobileScaleFactor = isMobile ? 0.5 : 1; // Halve the pen widths on mobile

        return {
            minWidth: Math.max(baseMinWidth * scaleFactor * mobileScaleFactor, 0.5), // Ensure minimum width of 0.25px
            maxWidth: Math.max(baseMaxWidth * scaleFactor * mobileScaleFactor, 1), // Ensure minimum width of 1px
        };
    };

    const updatePenWidths = () => {
        if (sigCanvasRef.current) {
            const canvasHeight = pdfCanvasRef.current.height;
            const dpr = window.devicePixelRatio || 1;
            const isMobile = window.innerWidth <= 768; // Example breakpoint for mobile devices
            const { minWidth, maxWidth } = calculatePenWidths(canvasHeight / dpr, isMobile);

            sigCanvasRef.current.off(); // Turn off existing event listeners
            sigCanvasRef.current.on(); // Re-attach event listeners with new pen widths
            sigCanvasRef.current.getCanvas().getContext('2d').minWidth = minWidth;
            sigCanvasRef.current.getCanvas().getContext('2d').maxWidth = maxWidth;
        }
    };

    const loadPdf = async () => {
        // Cancel any ongoing render task before starting a new one
        if (renderTaskRef.current) {
            try {
                await renderTaskRef.current.cancel();
                renderTaskRef.current = null;
            } catch (error) {
                console.error('Error cancelling previous render task:', error);
                return; // Exit if cancel fails
            }
        }

        const postData = {
            studentNumber: studentId,
            moduleCode: modCode,
            certCode: certCode,
            fileName: 'CoverSheet.pdf',
        };

        const fallbackPdf = coverSheet; // Local fallback PDF file

        // const pdf = await getDocument(coverSheet).promise;

        // Fetch the PDF using the hook
        let pdf;
        try {
            const { pdf: fetchedPdf, msg, msgCode } = await fetchPdf('assessment/coverSheet', postData, fallbackPdf); // Call the hook

            if (msgCode === 1) {
                // Success - PDF fetched from API
                pdf = fetchedPdf;
            } else {
                // If there's an error or fallback, log the message
                console.warn('API response:', msg);
                pdf = await getDocument(fallbackPdf).promise; // Load local fallback PDF if needed
            }
        } catch (error) {
            // If any errors occur during PDF fetching, fallback to local PDF
            console.error('Error fetching PDF, loading local fallback:', error);
            pdf = await getDocument(fallbackPdf).promise;
        }

        // Render the PDF (assuming you already have `pdf` object from above)
        try {
            const page = await pdf.getPage(1); // Get the first page of the PDF
            const viewport = page.getViewport({ scale: 1 });

            // Set up the canvas for rendering
            const canvas = pdfCanvasRef.current;
            const context = canvas.getContext('2d');
            const containerWidth = canvas.parentNode.offsetWidth;

            const devicePixelRatio = window.devicePixelRatio || 1;
            const scale = containerWidth / viewport.width;

            // Adjust canvas dimensions for the PDF
            canvas.width = viewport.width * scale * devicePixelRatio;
            canvas.height = viewport.height * scale * devicePixelRatio;
            canvas.style.width = `${viewport.width * scale}px`;
            canvas.style.height = `${viewport.height * scale}px`;

            // Set scaling for the context
            context.scale(devicePixelRatio, devicePixelRatio);

            // Render the PDF page onto the canvas
            const renderContext = {
                canvasContext: context,
                viewport: page.getViewport({ scale }),
            };

            renderTaskRef.current = page.render(renderContext);
            await renderTaskRef.current.promise; // Wait for the rendering to finish
            renderTaskRef.current = null; // Clear the render task once done

            // If it's the initial render, store the dimensions of the canvas
            if (initialPdfCanvasDimensions.width === 0) {
                setInitialPdfCanvasDimensions({
                    width: viewport.width * scale,
                    height: viewport.height * scale,
                });
            }
        } catch (error) {
            if (error.name !== 'RenderingCancelledException') {
                console.error('Error rendering page:', error);
            } else {
                // console.log('Rendering was cancelled:', error);
            }
        }
    };


    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    };

    const updateCanvasSize = debounce(async () => {
        if (pdfCanvasRef.current && sigCanvasRef.current) {

            const containerWidth = pdfCanvasRef.current.parentNode.offsetWidth;
            const devicePixelRatio = window.devicePixelRatio || 1;
            const containerHeight = containerWidth * 1.414; // A4 aspect ratio
            const sigCanvas = sigCanvasRef.current.getCanvas();
            const tempCanvas = document.createElement('canvas');

            tempCanvas.width = sigCanvas.width;
            tempCanvas.height = sigCanvas.height;
            tempCanvas.getContext('2d').drawImage(sigCanvas, 0, 0);

            pdfCanvasRef.current.width = containerWidth * devicePixelRatio;
            pdfCanvasRef.current.height = containerHeight * devicePixelRatio;
            pdfCanvasRef.current.style.width = `${containerWidth}px`;
            pdfCanvasRef.current.style.height = `${containerHeight}px`;

            sigCanvas.width = containerWidth * devicePixelRatio;
            sigCanvas.height = containerHeight * devicePixelRatio;
            sigCanvas.style.width = `${containerWidth}px`;
            sigCanvas.style.height = `${containerHeight}px`;

            const sigContext = sigCanvas.getContext('2d');
            sigContext.scale(devicePixelRatio, devicePixelRatio);
            sigContext.drawImage(tempCanvas, 0, 0, containerWidth * devicePixelRatio, containerHeight * devicePixelRatio);

            annotations.forEach((annotation, index) => {
                const input = inputRefs.current[index];
                if (input) {
                    const newTop = `${annotation.y}%`;
                    const newLeft = `${annotation.x}%`;
                    input.style.top = newTop;
                    input.style.left = newLeft;
                }
            });

            updatePenWidths();
            await loadPdf();

            // if (window.innerWidth > 1000) {
            //     setIsDrawingEnabled(true)
            // } else {
            //     setIsDrawingEnabled(false)
            // }
        }
    }, 200);

    useEffect(() => {
        // Initial update
        updateCanvasSize();

        // Add event listener to resize
        window.addEventListener('resize', updateCanvasSize);

        // Clean up function to remove the event listener
        return () => {
            if (renderTaskRef.current) {
                renderTaskRef.current.cancel();
            }
            window.removeEventListener('resize', updateCanvasSize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coverSheet]);

    const clearSignature = () => {
        sigCanvasRef.current.clear();
        setSignature(null);
        setTimestamp(null);
    };

    const handleDateChange = (date, name) => {
        setDates({ ...dates, [name]: date });
    };

    const handleInputChange = (e, name) => {
        setInputValues({
            ...inputValues,
            [name]: e.target.value, // Update the input value based on the name of the annotation
        });
    };

    // const generatePdf = async () => {
    //
    //     const postData = {
    //         studentID: studentId,
    //         modID: modId,
    //         moduleCode: modCode,
    //         certCode: certCode,
    //         apprenticeModID: apprenticeModsId,
    //         fileName: 'completed_coversheet.pdf', // Dynamically updating the filename if needed
    //     };
    //
    //     const response = await fetchApi('assessment/generate_pdf', postData);
    //
    //     console.log('PDF Genration response: ', response)
    // }

    const uploadFile = (file) => {

        return new Promise((resolve, reject) => {

            const formData = new FormData();  // Correctly instantiate FormData
            formData.append("file", file, file.name);  // Append the file
            formData.append("studentNumber", studentId);  // Append the student number
            formData.append("moduleCode", modCode);  // Append the module code
            formData.append("certCode", certCode);  // Append the certificate code

            const req = new XMLHttpRequest();

            req.onload = () => {
                if (req.status >= 200 && req.status < 300) {
                    resolve(true);
                } else {
                    reject(`Failed to upload file. Status: ${req.status}`);
                }
            };

            req.onerror = () => {
                reject("Failed to upload file.");
            };

            req.open("POST", "https://api.trainingprofessionals.com.au/assessment/upload");
            req.send(formData);
        });
    };

    useImperativeHandle(ref, () => ({
        saveSignature
    }));

    const saveSignature = async () => {

        const pdfCanvas = pdfCanvasRef.current;
        const signatureCanvas = sigCanvasRef.current.getCanvas();
        const scaleFactor = 1;
        const dpr = window.devicePixelRatio ; // Use the devicePixelRatio for higher resolution

        // Create a new canvas for the combined signature and PDF, scaling by device pixel ratio
        const combinedCanvas = document.createElement('canvas');
        // combinedCanvas.width = pdfCanvas.width * dpr;
        // combinedCanvas.height = pdfCanvas.height * dpr;
        combinedCanvas.width = pdfCanvas.width * scaleFactor;
        combinedCanvas.height = pdfCanvas.height * scaleFactor;

        const combinedContext = combinedCanvas.getContext('2d');

        // Scale the context to adjust for higher resolution
        // combinedContext.scale(dpr, dpr);
        combinedContext.scale(scaleFactor, scaleFactor);

        // Draw PDF and signature on the combined canvas, adjusted for scaling
        combinedContext.drawImage(pdfCanvas, 0, 0, pdfCanvas.width, pdfCanvas.height);
        combinedContext.drawImage(signatureCanvas, 0, 0, pdfCanvas.width, pdfCanvas.height);

        // Iterate over annotations and add input values to the canvas
        annotations.forEach((annotation, index) => {
            if (annotation.name === 'SignHere' || annotation.name === 'SignatureBox') return; // Skip signature box

            let fontSize = calculateFontSize(pdfCanvas.height, dpr); // Adjust font size for scaling
            let x = ((annotation.x / 100) * pdfCanvas.width);
            let y = (annotation.y / 102) * pdfCanvas.height;

            let inputValue = "";

            // Handle different input types
            if (annotation.input === 'checkbox') {
                fontSize *= 2; // Scale up checkbox font size
                // Get the checkbox checked state from inputRefs
                const isChecked = inputRefs.current[index]?.checked;
                inputValue = isChecked ? "✓" : ""; // Use "✓" for checked and blank for unchecked
            } else if (annotation.input === 'date') {
                // Format date values
                inputValue = dates[annotation.name] ? dayjs(dates[annotation.name]).format('DD/MM/YYYY') : "";
            } else if (!annotation.input) {
                // If no input type, treat it as a label
                inputValue = annotation.label;
            } else {
                // For text inputs, get the value from the inputRefs
                inputValue = inputRefs.current[index] ? inputRefs.current[index].value : "";
            }

            // Set font and color, then draw the input value on the canvas
            combinedContext.font = `${annotation.fontWeight} ${fontSize}px sans-serif`;
            combinedContext.fillStyle = annotation.color;
            combinedContext.fillText(inputValue, x, y);
        });

        // Convert the combined canvas to a high-resolution data URL
        const imgData = combinedCanvas.toDataURL('image/png', 1.0); // Full quality (1.0)

        // Create a new jsPDF instance and set the proper dimensions (PDF size remains the same)
        const pdf = new jsPDF('portrait', 'pt', [pdfCanvas.width, pdfCanvas.height]);

        // Add the high-quality image to the PDF
        pdf.addImage(imgData, 'PNG', 0, 0, pdfCanvas.width, pdfCanvas.height);

        // Create a Blob for the PDF file
        const pdfBlob = pdf.output('blob');
        const file = new File([pdfBlob], 'completed_coversheet.pdf', { type: 'application/pdf' });

        try {
            // Upload the file
            const response = await uploadFile(file);
            console.log('File uploaded successfully:', response);

            if (response) {

                const endpoint = 'assessment/updateStatus';
                const postData = {
                    studentId: studentId,
                    apprenticeModId: apprenticeModsId,
                    moduleId: modId,
                    isSigned_Student: true,
                    status: 'Started',
                };

                fetchApi(endpoint, postData)
                    .then(response => {
                        switch ( true ) {
                            case (response.status >= 200 && response.status < 300):
                                break;
                            default:
                                console.error("Error submitting status update: ", response);
                                break;
                        }
                    })
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    // const toggleDrawingCanvas = () => {
    //     setIsDrawingEnabled(!isDrawingEnabled);
    // };

    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.0)',
        zIndex: 3,
        // display: isDrawingEnabled ? 'none' : 'block', // Show when drawing is disabled
        // display: window.innerWidth > 1000 ? 'none' : 'block',
    };

    const mobileButtonStyle = {
        position: 'fixed',
        top: '50vh',  // Use viewport height units
        left: '65vw',    // Use viewport width units
        width: '10vw',  // Use viewport width units for consistent sizing
        height: '5vw', // Use viewport width units for consistent sizing
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: 'white',  // Ensure text color contrasts with the background
        border: 'none',
        borderRadius: '50%',
        fontSize: '1vw',  // Use viewport width units for consistent text size
        textAlign: 'center',
        lineHeight: '1vw', // Ensure line height matches the button height
        cursor: 'pointer',
        zIndex: 1000,
        display: window.innerWidth > 1000 ? 'none' : 'block', // Show only on mobile
    };

    const containerStyle = {
        padding: '25px',
        margin: '25px',
        border: 'none',
        // borderRadius: '4px',
        backgroundColor: 'white', // Changed for better visibility
        width: '100%', // Ensure it takes full width of its parent
        height: 'auto', // Height is automatically adjusted to maintain the aspect ratio
        aspectRatio: '1 / 1.414', // Use aspect ratio to maintain A4 dimensions
        // maxWidth: '1000px', // Maximum width
        // maxHeight: '1414px', // Maximum height based on A4 dimensions
        position: 'relative',
        boxShadow: '0px 4px 8px rgba(0,0,0,0.3)' // Shadow for floating effect
    };

    const canvasStyle = {
        width: '100%',
        height: '100%',
        border: 'none',
        // borderRadius: '15px',
        position: 'absolute',
        top: 0,
        left: 0,
    };

    const buttonContainerStyle = {
        width: '100%',
        margin: 'auto',
        // maxWidth: '1060px',
        padding: '10px 20px',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between',
        zIndex: 1000,
    };

    const buttonStyle = {
        padding: '10px 20px',
        fontSize: '16px',
        cursor: 'pointer',
    };

    return (

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>

            <div style={buttonContainerStyle}>
                <button className={'btn-primary'} style={buttonStyle} onClick={clearSignature}>Clear</button>
                <button className={'btn-primary'} style={buttonStyle} onClick={saveSignature}>Save</button>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', width: '100%' }}>
                <div style={containerStyle}>
                    <canvas ref={pdfCanvasRef} style={{ ...canvasStyle, zIndex: 1 }} />
                    <SignatureCanvas
                        ref={sigCanvasRef}
                        penColor="black"
                        minWidth={calculatePenWidths(pdfCanvasHeight).minWidth}
                        maxWidth={calculatePenWidths(pdfCanvasHeight).maxWidth}
                        canvasProps={{
                            style: {
                                width: '100%',
                                height: `100%`,
                                border: 'none',
                                borderRadius: '15px',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 2
                            },
                        }}
                    />
                    {/* <div style={overlayStyle} /> Add the overlay element */}

                    {annotations.map((annotation, index) => {
                        const dpr = window.devicePixelRatio || 1;
                        const fontSize = `${calculateFontSize(pdfCanvasHeight, dpr)}px`; // Calculate font size and normalize by DPR
                        return (
                            <div
                                key={index}
                                style={{
                                    position: 'absolute',
                                    top: `${annotation.y}%`,
                                    left: `${annotation.x}%`,
                                    transform: 'translateY(-100%)',
                                    zIndex: annotation.input ? 3 : 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'start',
                                    userSelect: 'none',
                                    fontSize,
                                    fontWeight: annotation.fontWeight,
                                    color: annotation.color,
                                    width: `${annotation.width}%`,
                                    maxWidth: annotation.name === 'ModName' ? 'none' : '100px',
                                }}
                            >
                                {annotation.input ? (

                                    annotation.input === 'checkbox' ? (

                                        <input
                                            type="checkbox"
                                            defaultChecked={false} // Temporarily checked for testing
                                            className="custom-form-checkbox"
                                            ref={(el) => (inputRefs.current[index] = el)}  // Maintain reference like other inputs
                                        />

                                    ) : annotation.input === 'date' ? (

                                        <DatePicker
                                            style={{}}
                                            selected={dates[annotation.name]}
                                            onChange={(date) => handleDateChange(date, annotation.name)}
                                            dateFormat="dd/MM/yyyy"
                                            dropdownMode="select"
                                            popperPlacement="top-center"
                                            placeholderText="Select Date"
                                            showYearDropdown     // Enables year dropdown
                                            scrollableYearDropdown   // Makes year dropdown scrollable (optional)
                                            yearDropdownItemNumber={15}  // Number of years shown in the dropdown, default is 5
                                            customInput={
                                                <input
                                                    type="text"
                                                    style={{
                                                        zIndex: 3,
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                        fontSize,
                                                        fontWeight: annotation.fontWeight,
                                                        color: annotation.color,
                                                        outline: 'none',
                                                        boxShadow: 'none',
                                                        cursor: 'pointer',
                                                        // width: '80%'
                                                    }}
                                                    ref={(el) => (inputRefs.current[index] = el)}
                                                />
                                            }

                                            wrapperClassName="custom-datepicker-wrapper"
                                            popperClassName="custom-datepicker-popper"
                                        />

                                    ) : (
                                        // <input
                                        //     type="text"
                                        //     placeholder={annotation.label}
                                        //     value={annotation.value}
                                        //     style={{
                                        //         zIndex: 3,
                                        //         border: 'none',
                                        //         backgroundColor: 'transparent',
                                        //         fontSize, // Apply dynamic font size
                                        //         fontWeight: annotation.fontWeight,
                                        //         color: annotation.color,
                                        //         boxShadow: 'none',
                                        //         outline: 'none',
                                        //     }}
                                        //     ref={(el) => (inputRefs.current[index] = el)}
                                        // />
                                        <input
                                            type="text"
                                            placeholder={annotation.label}
                                            value={inputValues[annotation.name]} // Controlled input value
                                            onChange={(e) => handleInputChange(e, annotation.name)} // Handle change to update state
                                            style={{
                                                zIndex: 3,
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                                fontSize, // Apply dynamic font size
                                                fontWeight: annotation.fontWeight,
                                                color: annotation.color,
                                                boxShadow: 'none',
                                                outline: 'none',
                                            }}
                                            ref={(el) => (inputRefs.current[index] = el)} // Update ref
                                        />
                                    )

                                ) : annotation.name === 'SignatureBox' ? (
                                    <div
                                        style={{
                                            width: `${(annotation.width / 100) * pdfCanvasWidth / dpr}px`,
                                            height: `${(annotation.height / 100) * pdfCanvasHeight / dpr}px`,
                                            zIndex: 1,
                                            backgroundColor: annotation.color,
                                            position: 'absolute'
                                        }}
                                    ></div>
                                ) : (
                                    <span
                                        style={{
                                            color: annotation.color,
                                            fontSize: annotation.fontSize || fontSize,
                                            fontWeight: annotation.fontWeight,
                                            zIndex: 1,
                                        }}
                                    >
                                    {annotation.label}
                                </span>
                                )}
                            </div>
                        );
                    })}

                    {signature && (
                        <div>
                            <h3>Saved Signature:</h3>
                            <img src={signature} alt="signature" style={{}} />
                            <p>Timestamp: {timestamp}</p>
                        </div>
                    )}
                </div>

                {/* <button
                    // className="mobile-toggle-button"
                    style={mobileButtonStyle}
                    onClick={toggleDrawingCanvas}
                >
                    {isDrawingEnabled ? 'Stop Signing' : 'Click to Sign'}
                </button> */}
            </div>
        </div>
    );
});

export default SignaturePanel;
