import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Bluey from "Components/Bluey"
import useFetchApi from "Services/FetchApi";
import { useUserDeets } from "CustomHooks";

// Redux
import { useUserField } from 'CustomHooks';

export default function Template() {

    const navigate = useNavigate();
    const fetchApi = useFetchApi();
    const user = useUserDeets();
    const pathArray = window.location.pathname.split('/');
    const pageParam = pathArray[pathArray.length - 1];
    const [pageName, setPageName] = useState('');


    useEffect(() => {
        setPageName(pageParam || 'Default Page');
    }, []);

    return (
        <div>

            <Bluey />

            <div className={"page-common"} style={{ marginTop: 100, zIndex: 1 }}>

                {pageName}

            </div>

        </div>
    );
}

// =============================== To Do =================================== //



// =============================== Bugs =================================== //