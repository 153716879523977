import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Bluey from "Components/Bluey";
import useFetchApi from "Services/FetchApi";
import { useUserDeets } from "CustomHooks";
import { Alert as CustomAlert } from "Components";
import './ClassroomLive.css';
import { useDropzone } from 'react-dropzone';
import { FiUpload } from 'react-icons/fi';

// Redux
// import { useUserField } from 'CustomHooks';

export default function ClassroomLive() {

    const navigate = useNavigate();
    const location = useLocation();
    const fetchApi = useFetchApi();
    const user = useUserDeets();
    const pathArray = window.location.pathname.split('/');
    const stateData = location.state ? location.state : null;
    const environment = process.env.REACT_APP_ENV;
    const [sharedFiles, setSharedFiles] = useState([]);
    const [alertMessage, setAlertMessage] = useState("");

    const [classId] = useState(stateData.classId);
    const [certName] = useState(stateData.certName);
    const [studentId] = useState(user.roleId);
    const [showPopup, setShowPopup] = useState(false);
    const [fileToUpload, setFileToUpload] = useState(null);
    const [viewMode, setViewMode] = useState('list'); // Default to list view
    const [isPrivate, setIsPrivate] = useState(false); // Default to public
    const [studentList, setStudentList] = useState([]);
    const [classTitle, setClassTitle] = useState('');
    const [time, setTime] = useState('');
    const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
    const [msgCode, setMsgCode] = useState(0);
    const [isWhiteboardExpanded, setWhiteboardExpanded] = useState(false);

    const server = () => {
        let api;

        switch (environment) {
            case 'production':
                api = 'https://api.trainingprofessionals.com.au';
                break;
            case 'development':
                api = 'https://10.1.10.49:5001';
                break;
            default:
                api = 'https://localhost:3001';
        }

        return api;
    }


    const toggleWhiteboard = () => {
        setWhiteboardExpanded(!isWhiteboardExpanded);
    };

    // Function to format and update the time
    const updateTime = () => {
        const now = new Date();
        let hours = now.getHours();
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM'; // Determine AM/PM

        // Convert hours to 12-hour format
        hours = hours % 12 || 12;

        setTime(`${hours}:${minutes} ${ampm}`);
    };

    useEffect(() => {
        updateTime(); // Update the time immediately on mount
        const interval = setInterval(updateTime, 15000); // Update every minute
        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    useEffect(() => {
        fetchFiles(); // Fetch files on component mount
    }, []);

    useEffect(() => {
        // console.log('ClassId:', classId);
        if (classId) {
            fetchClassDetails(classId);
        }
    }, [classId]);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchFiles(); // Refresh files every 60 seconds
        }, 60000); // 60 seconds

        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);

    const getCustomIconForFileType = (fileName) => {
        const extension = fileName.split(".").pop().toLowerCase(); // Extract file extension

        const iconMap = {
            pdf: "/icons/pdf.png",
            doc: "/icons/doc.png",
            docx: "/icons/doc.png",
            xls: "/icons/excel.png",
            xlsx: "/icons/excel.png",
            ppt: "/icons/ppt.png",
            pptx: "/icons/ppt.png",
            jpg: "/icons/jpg.png",
            jpeg: "/icons/jpeg.png",
            png: "/icons/png.png",
            gif: "/icons/gif.png",
            html: "/icons/html.png",
            css: "/icons/css.png",
            js: "/icons/unknown.png",
            zip: "/icons/zip.png",
            rar: "/icons/rar.png",
            exe: "/icons/exe.png",
            avi: "/icons/avi.png",
            mp3: "/icons/mp3.png",
            mp4: "/icons/mp4.png",
            psd: "/icons/psd.png",
            txt: "/icons/txt.png",
            default: "/icons/unknown.png",
        };

        return iconMap[extension] || iconMap["default"];
    };


    const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
        try {
            // Alert for unsupported files
            setAlertMessage("")

            if (rejectedFiles.length > 0) {
                const unsupportedFiles = rejectedFiles.map((file) => file.file.name).join(", ");
                setAlertMessage(`Unsupported files: ${unsupportedFiles}`);
            }

            if (acceptedFiles.length > 0) {
                setFileToUpload(acceptedFiles[0]); // Save the first file to upload
                setShowPopup(true); // Show the popup
            }

            // Upload supported files
            // for (const file of acceptedFiles) {
            //     await uploadFile(file); // Upload each file
            // }
            // fetchFiles(); // Refresh the shared directory after upload
        } catch (err) {
            console.error("Error uploading files:", err);
        }
    }, []);


    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        multiple: true, // Allow multiple file uploads
        accept: {
            'image/*': [], // Allow all image types
            'application/pdf': [], // Allow PDF files
            'text/plain': [], // Allow plain text files
            'video/*': [], // Allow all video types (e.g., mp4, avi, mov)
            'application/msword': [], // Allow .doc
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [], // Allow .docx
            'application/vnd.ms-excel': [], // Allow .xls
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // Allow .xlsx
            'application/vnd.ms-powerpoint': [], // Allow .ppt
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': [], // Allow .pptx
            'application/zip': [], // Allow .zip
            'application/x-rar-compressed': [], // Allow .rar
        },
    });

    const fetchClassDetails = async (classNum) => {
        fetchApi('classroom/class', {"classId": classNum})
            .then(response => {
                const students = response.responseData.students;
                console.log('Student List: ', students);
                if (response.status >= 200 && response.status < 300) {
                    // console.log(response.responseData)
                    setStudentList(students);
                    setClassTitle(response.responseData.class);
                    setMsgCode(0);
                } else {
                    setMsg(response.msg);
                    setMsgCode(4);
                }
            });
    };

    // const uploadFile = (file) => {
    //     return new Promise((resolve, reject) => {
    //         const formData = new FormData();
    //         formData.append("file", file, file.name);
    //         formData.append("studentId", studentId);
    //         formData.append("classId", classId);
    //
    //         const req = new XMLHttpRequest();
    //         req.open("POST", `${server()}/classroom/share_file`);
    //         req.withCredentials = true; // Include credentials in the request
    //
    //         req.onload = () => {
    //             if (req.status >= 200 && req.status < 300) {
    //                 resolve(true);
    //             } else {
    //                 console.error(`Upload failed with status ${req.status}:`, req.responseText);
    //                 reject(new Error(`Upload failed: ${req.status}`));
    //             }
    //         };
    //
    //         req.onerror = () => {
    //             console.error("Network error occurred during upload.");
    //             reject(new Error("Network error during upload"));
    //         };
    //
    //         req.send(formData);
    //     });
    // };

    const uploadFile = (file, isPrivate) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", file, file.name);
            formData.append("studentId", studentId);
            formData.append("classId", classId);
            formData.append("isPrivate", isPrivate); // Append the isPrivate flag

            const req = new XMLHttpRequest();
            req.open("POST", `${server()}/classroom/share_file`);
            req.withCredentials = true; // Include credentials

            req.onload = () => {
                if (req.status >= 200 && req.status < 300) {
                    resolve(true);
                } else {
                    reject(`Failed to upload file. Status: ${req.status}`);
                }
            };

            req.onerror = () => {
                reject("Failed to upload file.");
            };

            req.send(formData);
        });
    };

    const handleUserChoice = async (isPrivateValue) => {
        setShowPopup(false); // Hide the popup
        await handleUpload(fileToUpload, isPrivateValue); // Pass the isPrivate value directly
    };

    // const handleUpload = async () => {
    //     if (!fileToUpload) return;
    //
    //     try {
    //         await uploadFile(fileToUpload, isPrivate); // Pass isPrivate flag
    //         fetchFiles(); // Refresh shared directory
    //         // setAlertMessage(`${fileToUpload.name} uploaded successfully as ${isPrivate ? 'private' : 'public'}`);
    //         console.log(`${fileToUpload.name} uploaded successfully as ${isPrivate ? 'private' : 'public'}`);
    //     } catch (err) {
    //         console.error("Error uploading file:", err);
    //         // setAlertMessage(`Error uploading ${fileToUpload.name}`);
    //     } finally {
    //         setShowPopup(false); // Hide the popup
    //         setFileToUpload(null); // Reset file state
    //     }
    // };

    const handleUpload = async (file, isPrivateValue) => {
        if (!file) return; // Ensure there is a file to upload

        try {
            await uploadFile(file, isPrivateValue); // Upload the file with the passed isPrivate value

            console.log(`${file.name} uploaded successfully as ${isPrivateValue ? "private" : "public"}`);
        } catch (err) {
            console.error("Error uploading file:", err);
        } finally {
            setFileToUpload(null); // Clear the file state
            fetchFiles(); // Refresh the shared directory
        }
    };

    const downloadFile = (fileName, isPrivate) => {
        const formData = new FormData();
        formData.append("classId", classId);
        formData.append("studentId", user.roleId);
        formData.append("fileName", fileName);
        formData.append("isPrivate", isPrivate); // Or true, depending on privacy settings

        fetch(`${server()}/classroom/download_file`, {
            method: "POST",
            body: formData,
            credentials: "include", // Include cookies for authentication
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to download file");
                }
                return response.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = fileName; // Set file name for download
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((err) => console.error("Error downloading file:", err));
    };


    const fetchFiles = async () => {
        try {
            const response = await fetchApi("classroom/fetch_files", {
                studentId: user.roleId,
                classId
            });
            if (response.status >= 200 && response.status < 300) {
                setSharedFiles(response.responseData.files); // Store file list
            } else {
                console.error("Failed to fetch shared files:", response.message);
            }
        } catch (err) {
            console.error("Error fetching shared files:", err);
        }
    };



    const formatFileSize = (size) => {
        if (size < 1024) return `${size} B`;
        if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)} KB`;
        if (size < 1024 * 1024 * 1024) return `${(size / (1024 * 1024)).toFixed(1)} MB`;
        return `${(size / (1024 * 1024 * 1024)).toFixed(1)} GB`;
    };


    return (

        <>

            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup">
                        <h3>Set File Visibility</h3>
                        <p>Would you like this file to be public or private?</p>
                        <p>If set to private, only you and your trainer will be able to see it.</p>
                        {/*<div className="popup-actions">*/}
                        {/*    <button onClick={() => { setIsPrivate(false); handleUpload(); }}>*/}
                        {/*        Public*/}
                        {/*    </button>*/}
                        {/*    <button onClick={() => { setIsPrivate(true); handleUpload(); }}>*/}
                        {/*        Private*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                        <div className="popup-actions">
                            <button onClick={() => handleUserChoice(false)}>Public</button>
                            <button onClick={() => handleUserChoice(true)}>Private</button>
                        </div>
                    </div>
                </div>
            )}

            <div className="classroom-container">

                <header className="classroom-header">
                    <div className="classroom-header-content">
                        <h1>{classTitle}</h1>
                        <p>{certName}</p>
                    </div>
                    <div className="classroom-clock">{time}</div>
                </header>

                {/*<div className="classroom-player"></div>*/}

                {/*=== DO NOT DELETE ===*/}
                {/*<nav className="classroom-button-menu">*/}
                {/*    <button className="classroom-button">1</button>*/}
                {/*    <button className="classroom-button">2</button>*/}
                {/*    <button className="classroom-button">3</button>*/}
                {/*    <button className="classroom-button">4</button>*/}
                {/*    <button className="classroom-button">5</button>*/}
                {/*    <button className="classroom-button">6</button>*/}
                {/*</nav>*/}
                {/*=== DO NOT DELETE ===*/}

                <CustomAlert msg={msg} msgCode={msgCode}/>

                <main className="classroom-main">

                    <div className="classroom-top-row">
                        <div className="classroom-trainer">Trainer</div>
                        <div
                            className={`classroom-whiteboard ${
                                isWhiteboardExpanded ? "expanded" : ""
                            }`}
                            onClick={toggleWhiteboard}
                        >
                            {isWhiteboardExpanded ? "Media Player" : "Whiteboard"}
                        </div>
                    </div>

                    {studentList.map((learner) => (
                        <div key={learner.id} className="classroom-learner">
                            {learner.name}
                        </div>
                    ))}
                </main>

                <footer className="classroom-footer">
                    {alertMessage && (
                        <div className="alert">
                            {alertMessage}
                            <button onClick={() => setAlertMessage("")}></button>
                        </div>
                    )}
                    <div className="classroom-upload-zone" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <FiUpload className="upload-icon"/>
                        {isDragActive ? (
                            <p className="upload-text">Drop the items here...</p>
                        ) : (
                            <>
                                <p className="upload-text">
                                    Drag & drop items here or <span className="browse-files">Browse files</span>
                                </p>
                                <p className="upload-info">Up to 25 MB • File names without special characters</p>
                            </>
                        )}
                    </div>


                    <div className="classroom-shared-files">
                        <div className="shared-files-header">
                            <h3>Shared Files</h3>
                            <div className="header-actions">
                                <button className="refresh-button" onClick={fetchFiles}>
                                    <span className="refresh-icon">🔄</span>
                                </button>
                                <div className="view-toggle">
                                    <button
                                        className={`toggle-button ${viewMode === 'list' ? 'active' : ''}`}
                                        onClick={() => setViewMode('list')}
                                    >
                                        List View
                                    </button>
                                    <button
                                        className={`toggle-button ${viewMode === 'icon' ? 'active' : ''}`}
                                        onClick={() => setViewMode('icon')}
                                    >
                                        Icon View
                                    </button>
                                </div>
                            </div>
                        </div>
                        {viewMode === 'list' ? (
                            <ul className="shared-file-list">
                                {sharedFiles.map((file, index) => (
                                    <li key={index} className="shared-file-item">
                                        <img
                                            src={getCustomIconForFileType(file.name)}
                                            alt={`${file.name} icon`}
                                            className="file-icon"
                                        />
                                        <span className="file-name" onDoubleClick={() => downloadFile(file.name)}>
                                            {file.name}
                                        </span>
                                        {file.isPrivate && <span className="file-tag">Private</span>}
                                        <span
                                            className="file-size">{formatFileSize(file.size)}</span> {/* File size here */}
                                        <button
                                            className="download-button"
                                            onClick={() => downloadFile(file.name)}
                                        >
                                            Download
                                        </button>

                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="learner-resources">
                                {sharedFiles.map((file, index) => (
                                    <div className="resource-item" key={index}>
                                        <a
                                            onDoubleClick={() => downloadFile(file.name, file.isPrivate)}
                                            href="#"
                                        >
                                            <img
                                                src={getCustomIconForFileType(file.name)}
                                                alt={file.name}
                                                className="resource-icon"
                                            />
                                            <p className="resource-name">{file.name}</p>
                                            {file.isPrivate && <span className="file-tag">Private</span>}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>


                </footer>

                <aside className="classroom-chat-bar">
                    Chat Bar
                </aside>
            </div>


        </>
    )
        ;

}