import React, { useRef, useEffect, useState } from "react";

interface AutoWidthInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AutoWidthInput: React.FC<AutoWidthInputProps> = ({ value, placeholder, onChange, ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputWidth, setInputWidth] = useState<number>(10); // Default width for empty content

  useEffect(() => {
    const calculateWidth = () => {
      if (inputRef.current) {
        const span = document.createElement("span");
        span.style.visibility = "hidden";
        span.style.position = "absolute";
        span.style.whiteSpace = "pre";
        span.style.font = window.getComputedStyle(inputRef.current).font;
        span.textContent = value || placeholder || "";
        document.body.appendChild(span);
        setInputWidth(span.offsetWidth + 10); // Add some padding
        document.body.removeChild(span);
      }
    };

    calculateWidth();
  }, [value, placeholder]); // Recalculate width whenever value or placeholder changes

  return (
    <input
      ref={inputRef}
      value={value}
      placeholder={placeholder}
      onChange={onChange} // Ensure state updates on change
      {...props}
      style={{ width: `${inputWidth}px`, ...props.style }}
    />
  );
};

export default AutoWidthInput;
