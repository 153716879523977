import { useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";

const InterceptRoute = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  useEffect(() => {
    if (id) {
      console.log(`Intercepted ID: ${id}`);
    }
  }, [id]);

  if (id) {
    return <Navigate to="/class-enrol-student" replace />;
  }

  return <div>No ID provided in query parameters.</div>;
};

export default InterceptRoute;
