export { default as Assessment } from "./Study/Assessment";
export { default as Classroom } from "./Study/Classroom";
export { default as ClassroomLive } from "./Study/ClassroomLive";
export { default as Diary } from "./Study/Diary";
export { default as PersonalDetails } from "./Study/PersonalDetails";
export { default as Competencies } from "./Competencies";
export { default as Contact } from "./Contact";
export { default as CoursesTraining } from "./CoursesTraining";
export { default as Employment } from "./Employment";
// export { default as Enrol } from "./Enrol";
export { default as EventViewer } from "./EventViewer";
export { default as Home } from "./Home";
export { default as Login } from "./Login";
export { default as Register } from "./Register";
export { default as Study } from "./Study";
export { default as Template } from "./Template";
export {default as Error} from "./Dev/Error";
export {default as Landing} from "./Landing";
export { default as Construction } from "./Dev/Construction";
export { default as Feedback } from './Feedback';
export { default as DynamicForm } from './DynamicForm';
export { default as StudentEnrol } from './StudentEnrol';
export { default as PasswordRecovery } from './PasswordRecovery';
