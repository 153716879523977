import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

interface WorkflowData {
  workflowName: string;
  currentForm: number;
  currentFormVersion: number;
  nextForm: number;
  nextFormVersion: number;  
  nextFormAvailable: boolean;
}

interface WorkflowState {
  workflowData: WorkflowData | null;
}

const initialState: WorkflowState = {
  workflowData: null
};

// Define an async thunk
export const setWorkflowDataAsync = createAsyncThunk(
  'workflow/setWorkflowDataAsync',
  async (workflowData: WorkflowData, { dispatch }) => {
    // Simulate a delay or some async process if needed
    await new Promise((resolve) => setTimeout(resolve, 0)); // Simulating an async operation
    dispatch(setWorkflowData(workflowData)); // Dispatch the synchronous action
    return workflowData;
  }
);

const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    setWorkflowData(state, action: PayloadAction<WorkflowData>) {
      state.workflowData = action.payload;
    },
    clearWorkflowData(state) {
      state.workflowData = null;
    }
  }
});

export const { setWorkflowData, clearWorkflowData } = workflowSlice.actions;
export default workflowSlice.reducer;
