import React from 'react';

import './Styles/fingerprintBtn.scss';

interface FingerprintBtnProps {
  label?: string;
  onClick?: () => void;
}


const FingerprintBtn: React.FC<FingerprintBtnProps> = ({ label='this needs a label', onClick }) => {
  return (
    <div className='fingerprint-container' onClick={onClick}>
      <i className="fas fa-fingerprint" />
      <span id='fingerprint-label'>{label}</span>
    </div>
  );
};

export default FingerprintBtn;