import React from 'react';

import './Styles/toDoCard.scss';

interface ToDoCardProps {
  priority: 0 | 1 | 2 | 3 | 4;
  link: string;
  content: string;
  status:  0 | 1 ;
}

const ToDoCard: React.FC<ToDoCardProps> = ({ priority, link, content, status}) => {
  let className = 'priority';

  switch (priority) {
    case 0:
      break;
    case 1:
      className += '-1';
      break;
      case 2:
      className += '-2';
      break;
      case 3:
        className += '-3';
        break;
      case 4:
        className += '-4';
        break;
    default:
      break;
  }

  if (priority > 0 && status > 0) {
    return ( 
      <div className={`to-do-container ${className}`}>
        <div className={`to-do-card ${className}`} onClick={() => console.log('link:', link)}>
          {content}
        </div>
      </div>
    );
  } else {
    return null;  
  }
    
};

export default ToDoCard;