import React, { useEffect } from 'react';
import { Form, Alert } from 'Components';
import { useFetchApi } from 'CustomHooks';


interface DynamicFormProps {
  currentForm: number | null;
  formVersion: number | null;
}

const DynamicForm: React.FC<DynamicFormProps> = ({currentForm, formVersion}) => {
  const fetchApi = useFetchApi();
  const [formData, setFormData] = React.useState<any>(null);
  const [msg, setMsg] = React.useState<string>('');
  const [msgCode, setMsgCode] = React.useState<0 | 1 | 2 | 3 | 4>(0);

  useEffect(() => {
    if (currentForm === null || formVersion === null) {
      setMsg('No formCode or version data found.');
      setMsgCode(1);
      return;
    }
  
    const fetchFormData = async () => {
      try {
        const response = await fetchApi("forms/goGetBarry", {
          formCode: currentForm,
          version: formVersion,
        });
    
        if (response.status === 200) {
          const responseData = response.responseData as any;
          setFormData(responseData);
        } else {
          console.warn("Non-200 response code:", response.status);
          setMsg("Error fetching form data.");
          setMsgCode(1);
        }
      } catch (error) {
        console.error("Fetch error:", error);
        setMsg("Error fetching form data.");
        setMsgCode(1);
      }
    };
    fetchFormData();   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentForm, formVersion]);


  if (!formData) {
    return (
      <div>
        <Alert msg={msg} msgCode={msgCode} />
        <div>Loading your form ...</div>
      </div>
    );
  } else {
    return (
      <div>
        <Alert msg={msg} msgCode={msgCode} />
        <Form formData={formData}/>
      </div>
    );
  }
};

export default DynamicForm;