import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FingerprintBtn } from "Components";

import "./Styles/classroomCard.scss";

interface ClassroomCardProps {
  code: string;
  name: string;
  status: string | "ENROLLED";
  description: string;
  startDate: string;
  endDate: string;
  first?: boolean;
  available?: boolean;
  onClick?: () => void;
  buttonText?: string;
}

const ClassroomCard: React.FC<ClassroomCardProps> = ({
  code,
  name,
  status,
  description,
  startDate,
  endDate,
  first,
  available,
  onClick,
  buttonText,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = (event: any) => {
    event?.stopPropagation();
    setShowDetails(!showDetails);
  };

  return (
    <div 
      className={`classroom-card-container ${status.toLowerCase()} ${first ? 'first' : ''} ${available ? 'active' : 'not-active'}`}
      onClick={onClick}
    >
      <div className={`${available ? 'available' : 'not-available'}`}>{name}<span>({code})</span></div>
      
      <div className='more-details flex-container column' style={{ display: showDetails ? 'flex' : 'none' }}>
        <div className='horizontal-divider no-glow' />
        {/* <p>{status}</p> */}
        <p><strong>Description:</strong><em> {description}</em></p>
        <p><strong>Start Date:</strong><em> {startDate}</em></p>
        <p><strong>End Date:</strong><em> {endDate}</em></p>
      </div>

      <div 
        className='flex-container button-container' 
        style={{ justifyContent: available ? 'space-between' : 'center'}}
      >
        {available && 
        <div className='indicator-text'> 
          <FingerprintBtn label={buttonText}/>
        </div>
        }
        <div className='more-details-button-container'>
          <FontAwesomeIcon
            className='more-details-button'
            icon={showDetails ? faMinus : faPlus}
            onClick={toggleDetails}
          />
        </div>
      </div>

    </div>
  );
};

export default ClassroomCard;
