import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Legend from './Legend';

// import '../Styles/main.css';
import './Styles/certCarousel.scss';

const CertCarousel = ({ items }) => {
    const [showTooltip, setShowTooltip] = useState(true); // For mobile only
    const [index, setIndex] = useState(0);
    const refWrapper = useRef(null);
    const touchStartX = useRef(0);
    const touchEndX = useRef(0);
    const navigate = useNavigate();

    console.log('Cert Carousel Items: ', items);

    const hasMultipleItems = items.length > 1;

    const nextCard = () => {
        setIndex((current) => (current + 1 + items.length) % items.length);
    };

    const prevCard = () => {
        setIndex((current) => (current - 1 + items.length) % items.length);
    };

    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
        touchEndX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
        if (touchStartX.current - touchEndX.current > 50) {
            nextCard(); // Swiped left
        }
        if (touchStartX.current - touchEndX.current < -50) {
            prevCard(); // Swiped right
        }
    };

    useEffect(() => {
        if (refWrapper.current) {
            const shift = index * -100 / items.length;
            refWrapper.current.style.transform = `translateX(${shift}%)`;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index]);

    // Function to determine the card class based on the certificate code
    const getCardClass = (item) => {
        // Treat blank, 'SOA', or 'SOC' codes as 'statement-card'
        const code = item.certificate?.code?.trim().toLowerCase();
        return (!code || code === 'soa' || code === 'soc') ? 'statement-card' : 'certificate-card';
    };

    // Wrapper function to decide which format function to use
    // const getOutcomeOrStatus = (proposedOutcome, statusID) => {
    //     if (proposedOutcome === null || proposedOutcome === undefined || proposedOutcome === '') {
    //         return formatProposedStatusShort(statusID);
    //     }
    //     return formatProposedOutcome(proposedOutcome);
    // };
    //
    // // Function to format proposedOutcome
    // const formatProposedOutcome = (outcome) => {
    //     switch (outcome) {
    //         case 20:
    //             return `C`;
    //         case 30:
    //             return 'NYC';
    //         case 40:
    //             return 'W';
    //         case 51:
    //             return 'RPL';
    //         case 52:
    //             return 'NRP';
    //         case 60:
    //             return `CT`;
    //         case 61:
    //             return 'SS';
    //         case 70:
    //             return 'CE';
    //         case 81:
    //             return 'NAC';
    //         case 82:
    //             return 'NAN';
    //         case 90:
    //             return 'NYA';
    //         case null:
    //             return `???`;
    //         default:
    //             return `N/A`;
    //     }
    // };
    //
    // const formatProposedStatusShort = (outcome) => {
    //     switch (outcome) {
    //         case 1:
    //             return `NYE`;
    //         case 2:
    //         case 3:
    //         case 4:
    //             return `E`;
    //         case 5:
    //         case 6:
    //         case 7:
    //         case 8:
    //         case 9:
    //             return `C*`;
    //         case 10:
    //             return `W`;
    //         case 11:
    //             return `?`;
    //         case 13:
    //             return `C*`;
    //         case 14:
    //             return `C`;
    //         default:
    //             return 'N/A';
    //     }
    // };
    //
    // const formatProposedStatus = (outcome) => {
    //     switch (outcome) {
    //         case 1:
    //             return `NOT YET ENROLLED`;
    //         case 2:
    //         case 3:
    //         case 4:
    //             return `ENROLLED`;
    //         case 5:
    //         case 6:
    //         case 7:
    //         case 8:
    //         case 9:
    //             return `COMPETENT`;
    //         case 10:
    //             return `WITHDRAWN`;
    //         case 11:
    //             return `UNKNOWN`;
    //         case 13:
    //             return `COMPETENT`;
    //         case 14:
    //             return `COMPLETED`;
    //         default:
    //             return 'N/A';
    //     }
    // };

    const formatStatus = (outcome, status, type) => {

        let value = outcome;

        if (outcome === 0 || outcome === null || outcome === undefined || outcome === '') {
            value = status;
        }

        switch (value) {
            case 1:
                return type === 'pretty' ? 'NOT YET ENROLLED' : 'NYE';
            case 2:
            case 3:
            case 4:
                return type === 'pretty' ? 'ENROLLED' : 'E';
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
                return type === 'pretty' ? 'COMPETENT' : 'C*';
            case 10:
                return type === 'pretty' ? 'WITHDRAWN' : 'W';
            case 11:
                return type === 'pretty' ? 'UNKNOWN' : '?';
            case 13:
                return type === 'pretty' ? 'COMPETENT' : 'C*';
            case 14:
            case 20:
                return type === 'pretty' ? 'COMPLETED' : 'C';
            case 30:
                return 'NYC';
            case 40:
                return 'W';
            case 51:
                return 'RPL';
            case 52:
                return 'NRP';
            case 60:
                return 'CT';
            case 61:
                return 'SS';
            case 70:
                return 'CE';
            case 81:
                return 'NAC';
            case 82:
                return 'NAN';
            case 90:
                return 'NYA';
            case null:
                return '???';
            default:
                return 'N/A';
        }

    };

    // Function to format certificate name for statement of attainment
    const formatCertificateName = (certificate) => {
        const code = certificate?.code?.trim().toLowerCase();
        if (code === 'soa') {
            return 'Statement of Attainment';
        } else if (code === 'soc') {
            return 'Statement of Completion';
        } else {
            return certificate?.name || 'N/A'; // Default to the actual name or 'N/A'
        }
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        let formattedDate = date.toLocaleDateString('en-GB', options); // Example: "7 May 1984"

        // Extract day number to append the ordinal suffix
        const day = date.getDate();
        const suffix = ["th", "st", "nd", "rd"][((day % 100) - 20) % 10] || ["th", "st", "nd", "rd"][day % 100] || "th";

        // Replace the day number with the number+suffix
        formattedDate = formattedDate.replace(day, `${day}${suffix}`);

        return `${formattedDate}`;
    }

    const handleNavigate = ( classId, modules, certificate, certCode ) => {
        const jsonObject = {
            classId,
            certCode,
            certificate,
            modules
        };

        // Navigate with State method
        navigate('/study/classroom', { state: { jsonObject }, replace: true });
    };

    const handleClassroomClick = (item) => {
        console.log('Classroom Item: ', item)
        const certModulesStr = JSON.stringify(item.certModules);

        let classID = item.classId

        if (classID === null || classID === undefined || classID === '' || classID === 0) {
            if (item.certCustom && item.certCustom.classID) {
                classID = item.certCustom.classID
            } else if (item.statementDetails && item.statementDetails.classID) {
                classID = item.statementDetails.classID
            }
        }

        handleNavigate(
          classID,
          certModulesStr,
          formatCertificateName(item.certificate),
          item.certificate.code
        );
    };


    useEffect(() => {
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 3000);
    }, []);

    return (

        <div className='cert-container'>

            <Legend/>
            
        
            {showTooltip && 
                <span className='cert-tooltip mobile-only'>
                    Touch an incomplete certificate to enter the Classroom
                </span>
            }
            
            <div className="infinite-carousel-container" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}
                 onTouchEnd={handleTouchEnd}>

                <button onClick={prevCard} className="infinite-carousel-arrow left" style={{visibility: hasMultipleItems ? 'visible': 'hidden'}}>
                    <i className="fas fa-arrow-left"></i>
                </button>

                <div className="infinite-carousel-cards-wrapper" ref={refWrapper} style={{width: `${100 * items.length}%`}}>
                    {items.map((item, idx) => (
                        
                        <div key={idx} className={`infinite-carousel-card ${getCardClass(item)}`}
                        style={{width: `${100 / items.length}%`}}>
                            <div className="card-content clickable" onClick={() => handleClassroomClick(item)}>
                
             
                                {!['COMPETENT'].includes(formatStatus(item.certCustom.certificateStatusID)) && (
                                    <div className="hover-background-overlay desktop-only">
                                        <span className='hover-overlay-text'>Enter Classroom</span>
                                    </div>
                                )}
                                <div className="background-image"></div>


                                {
                                    <h4 style={{ padding: 50 }}>
                                        {(item.certificate?.code?.trim().toLowerCase() === 'soa' || item.certificate?.code?.trim().toLowerCase() === 'soc') ?
                                            formatCertificateName(item.certificate) :
                                            (item.certificate?.name || 'N/A')
                                        }
                                    </h4>
                                }
                                {
                                    (item.certificate?.code && !['soa', 'soc'].includes(item.certificate.code.trim().toLowerCase())) && (
                                        <h4 style={{ paddingBottom: 25, color: '#C5B358', zIndex: 25 }}>{item.certificate.code}</h4>
                                    )
                                }

                                <div style={{}} className="modules-section">
                                    {item.certModules?.map((module, moduleIdx) => (
                                        <div key={moduleIdx} className="module-item" style={{
                                            display: 'grid',
                                            gridTemplateColumns: '2fr 9fr 1fr',
                                            alignItems: 'flex-start',
                                            marginBottom: '10px'
                                        }}>
                                            <div className="module-code" style={{textAlign: 'left'}}>
                                                <h6 style={{"paddingRight": 10}}>{module.modDetails.code || 'N/A'}</h6>
                                            </div>
                                            <div className="module-name" style={{textAlign: 'left'}}>
                                                <h6 style={{margin: 0}}>{module.modDetails.name || 'N/A'}</h6>
                                            </div>
                                            <div className="module-code-right" style={{textAlign: 'center'}}>
                                                <h6 style={{margin: 0}}>{formatStatus(module.proposedOutcome, module.statusID)}</h6>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <h5 style={{paddingTop: 15, paddingBottom: 15}}>
                                    {formatStatus(item.certCustom.certificateStatusID, null, 'pretty')}
                                </h5>

                                {['COMPLETED', 'COMPETENT'].includes(formatStatus(item.certCustom.certificateStatusID, null, 'pretty')) && item.statementDetails && (
                                    <div>
                                        <h6 style={{ fontWeight: 'normal' }}>Awarded to</h6>
                                        <h5 style={{ color: '#C5B358', zIndex: 35 }}>{item.statementDetails.name}</h5>
                                        <h6 style={{ fontWeight: 'normal' }}>on the</h6>
                                        <h5 style={{ }}>{formatDate(item.statementDetails.date)}</h5>
                                    </div>
                                )}

                                <h6>** Please note that this is not a certificate **</h6>

                                {/*{['Completed', 'Competent'].includes(formatProposedStatus(item.certCustom.certificateStatusID)) && item.statementDetails && (*/}

                                    <div className="button-group"

                                         style={{display: 'flex', justifyContent: 'space-between', marginTop: 'auto'}}>

                                        {['COMPETENT'].includes(formatStatus(item.certCustom.certificateStatusID)) && (
                                            <button className={'btn-primary'}
                                                    style={{ padding: '10px', cursor: 'pointer' }}
                                                    onClick={() => handleClassroomClick(item)}>
                                                Classroom
                                            </button>
                                        )}

                                        {!['COMPLETED', 'COMPETENT'].includes(formatStatus(item.certCustom.certificateStatusID)) && item.statementDetails && (
                                            <>
                                                <button
                                                    className="btn-primary"
                                                    style={{ padding: '10px', cursor: 'pointer', zIndex: 101}}
                                                    onClick={() => window.open(`https://tp.edu.au/portal/tpro-files/backend/${item.certificate?.code?.trim().toLowerCase() === 'soa' || item.certificate?.code?.trim().toLowerCase() === 'soc' ? 'pdf-statement' : 'pdf-certificate'}.php?id=${item.certCustom.statementID || item.statementDetails.id}&mode=I`, '_blank')}
                                                >
                                                    View Certificate
                                                </button>
                                                <button
                                                    className="btn-primary"
                                                    style={{ padding: '10px', cursor: 'pointer', zIndex: 101 }}
                                                    onClick={() => window.location.href = `https://tp.edu.au/portal/tpro-files/backend/${item.certificate?.code?.trim().toLowerCase() === 'soa' || item.certificate?.code?.trim().toLowerCase() === 'soc' ? 'pdf-statement' : 'pdf-certificate'}.php?id=${item.certCustom.statementID || item.statementDetails.id}`}
                                                >
                                                    <i className="fas fa-download"></i>
                                                </button>
                                            </>
                                        )}

                                    </div>

                                {/*)}*/}

                            </div>
                        </div>
                    ))}
                </div>

                <button onClick={nextCard} className="infinite-carousel-arrow right" style={{visibility: hasMultipleItems ? 'visible': 'hidden'}}>
                    <i className="fas fa-arrow-right"></i>
                </button>

            </div>
        </div>
    );
};


export default CertCarousel;
