import React from 'react';

interface PasswordRecoveryProps {}

const PasswordRecovery: React.FC<PasswordRecoveryProps> = () => {
  return (
    <div className='password-recovery-container'>
     PasswordRecovery route
    </div>
  );
};

export default PasswordRecovery;