// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.learner-resources {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Flexible grid */
    gap: 15px; /* Spacing between items */
    justify-items: start; /* Align items to the left */
}

.resource-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 25px;
}

.resource-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 5px;
}

.resource-name {
    font-size: 12px;
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: anywhere;
    white-space: normal;
    max-width: 100%;
    line-height: 1.2;
    margin: 5px 0 0;
}

/* Remove underline and inherit color */
.learner-resources a {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit parent color */
}

@media (max-width: 768px) {
    .resource-item {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: calc(50% - 15px); /* 2 items per row for smaller screens */
        max-width: calc(50% - 15px);
    }
}

@media (max-width: 480px) {
    .resource-item {
        flex: 1 1 100%; /* 1 item per row for very small screens */
        max-width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Study/Classroom.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,4DAA4D,EAAE,kBAAkB;IAChF,SAAS,EAAE,0BAA0B;IACrC,oBAAoB,EAAE,4BAA4B;AACtD;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB;;AAEA,uCAAuC;AACvC;IACI,qBAAqB,EAAE,qBAAqB;IAC5C,cAAc,EAAE,yBAAyB;AAC7C;;AAEA;IACI;QACI,YAA0B;QAA1B,cAA0B;QAA1B,4BAA0B,EAAE,wCAAwC;QACpE,2BAA2B;IAC/B;AACJ;;AAEA;IACI;QACI,cAAc,EAAE,0CAA0C;QAC1D,eAAe;IACnB;AACJ","sourcesContent":[".learner-resources {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Flexible grid */\n    gap: 15px; /* Spacing between items */\n    justify-items: start; /* Align items to the left */\n}\n\n.resource-item {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n    margin-bottom: 25px;\n}\n\n.resource-icon {\n    width: 50px;\n    height: 50px;\n    margin-bottom: 5px;\n}\n\n.resource-name {\n    font-size: 12px;\n    text-align: center;\n    word-wrap: break-word;\n    overflow-wrap: anywhere;\n    white-space: normal;\n    max-width: 100%;\n    line-height: 1.2;\n    margin: 5px 0 0;\n}\n\n/* Remove underline and inherit color */\n.learner-resources a {\n    text-decoration: none; /* Remove underline */\n    color: inherit; /* Inherit parent color */\n}\n\n@media (max-width: 768px) {\n    .resource-item {\n        flex: 1 1 calc(50% - 15px); /* 2 items per row for smaller screens */\n        max-width: calc(50% - 15px);\n    }\n}\n\n@media (max-width: 480px) {\n    .resource-item {\n        flex: 1 1 100%; /* 1 item per row for very small screens */\n        max-width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
