 import { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Bluey, SpacerDiv } from "../Components";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomAlert from "../Components/CustomAlert";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PasswordStrengthBar from "react-password-strength-bar";
import { useUserData } from "CustomHooks";
// import { Alert, AlertTitle } from '@mui/material'
// import { faFacebook, faGoogle, faWindows } from '@fortawesome/free-brands-svg-icons';
// import logError from "../Components/Logger";  //  error handler
import "./Styles/register.scss";

export default function Enrol() {
  const navigate = useNavigate();
  const { setUserData } = useUserData();

  const [inputs, setInputs] = useState({});
  const [msg, setMsg] = useState(window.sessionStorage.getItem("message"));
  const [msgCode, setMsgCode] = useState(0);
  const [password, setPassword] = useState("");
  const [_confirmPassword, setConfirmPassword] = useState("");

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleLogin = (data) => {
    // // REMOVE: Can we remove all of this and just use the redux store?
    // console.log(data);
    // window.sessionStorage.setItem("email", data.email);
    // window.sessionStorage.setItem("username", data.username);
    // window.sessionStorage.setItem("user_name", data.user_name);
    // window.sessionStorage.setItem("user_id", data.user_id);
    // window.sessionStorage.setItem("profile_id", data.profile_id);
    // window.sessionStorage.setItem("timerSet", "no");
    // window.sessionStorage.setItem("userLoggedIn", "true");
    // window.sessionStorage.setItem("message", "You are signed in.");
    // window.sessionStorage.setItem("timerStartTime", "");
    // window.localStorage.setItem("timerStartTime", Date.now());

    setUserData(data); // This also sets logged in to TRUE
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    if (event.target.name === "password") {
      setPassword(event.target.value);
    } else if (event.target.name === "password2") {
      setConfirmPassword(event.target.value);
    }
  };

  const navigateTo = (address) => {
    navigate(address);
  };

  // const handleFacebookLogin = () => {
  //     // Logic to handle Facebook login
  // };

  // const handleGoogleLogin = () => {
  //     // Logic to handle Google login
  // };

  // const handleMicrosoftLogin = () => {
  //     // Logic to handle Microsoft login
  // };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (inputs.password === inputs.password2) {
      fetch("https://api.qrmezy.com/user/register", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(inputs),
        credentials: "include",
      })
        .then((response) =>
          response.json().then((data) => {
            console.log(response.status);

            if (response.status === 201) {
              console.log("User created and signed in");
              window.location.href = "/profile";
              setMsg("User created and signed in successfully!");
              handleLogin(data);
              setMsgCode(4);
            } else if (response.status === 400) {
              console.log(
                "Request body incomplete, both email and password are required"
              );
              setMsg("Please enter a valid username and password.");
              setMsgCode(1);
            } else if (response.status === 405) {
              console.log("Password did not meet requirements");
              setMsg(
                "Your password does not meet the required security level. Please ensure that it" +
                  " contains at least 8 characters, including at least one uppercase letter, one lowercase " +
                  "letter, one number, and one special character."
              );
              setMsgCode(2);
            } else if (response.status === 409) {
              console.log("User already exists");
              setMsg("User already exists");
              setMsgCode(1);
            } else if (response.status === 429) {
              console.log("Too many requests");
              setMsg("Too many requests");
              alert("WOOOAH... Too many requests - Slow down Turbot!");
              setMsgCode(2);
            } else {
              console.log("O_o    Sumfins Fucky");
              setMsg("A network error has occurred. Please try again later!");
              setMsgCode(3);
            }
          })
        )

        .catch((err) => err);
    } else {
      console.log("Passwords do not match");
      setMsg("The passwords you entered do not match");
      setMsgCode(3);
    }
  };

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  return (
    <form onSubmit={handleSubmit} className="register-container">
      <Bluey />
      <h3>REGISTER FOR A SHINY NEW ACCOUNT</h3>

      <CustomAlert msg={msg} msgCode={msgCode} />

      <input
        type="email"
        name="email"
        className="inputBox"
        placeholder={"ENTER YOUR EMAIL ADDRESS"}
        title="This will be your Username."
        onChange={handleChange}
        required
      />
      <input
        type="text"
        name="firstName"
        className="inputBox"
        placeholder={"FIRST NAME"}
        onChange={handleChange}
        title="Please enter you first name."
        required
      />
      <input
        type="text"
        name="lastName"
        className="inputBox"
        placeholder={"LAST NAME"}
        onChange={handleChange}
        title="Please enter your last name."
        required
      />
      <input
        type="text"
        name="dateOfBirth"
        className="inputBox"
        placeholder="DATE OF BIRTH"
        onFocus={(e) => (e.target.type = "date")}
        title="Please enter your Date of Birth."
        onChange={handleChange}
        required
      />

      <div className="input-container">
        <input
          type={showPassword1 ? "text" : "password"}
          name="password"
          className="inputBox"
          placeholder={"ENTER A PASSWORD"}
          onChange={handleChange}
          style={{ paddingRight: "40px" }}
          title="Please ensure you use a minimum of 8 characters including at least 1
                                    Uppercase letter, 1 number, and 1 special character. ie #Password1234."
        />
        <FontAwesomeIcon
          className="eye-icon"
          icon={showPassword1 ? faEyeSlash : faEye}
          onClick={togglePasswordVisibility1}
        />
        <SpacerDiv rem={0.5} />
        <PasswordStrengthBar
          password={password}
          barColors={["#e6d9f7", "#c9aef0", "#b389e8", "#9e66df", "#955cce"]}
          scoreWordStyle={{
            color: "#955cce",
            display: "inline-block",
          }}
        />
      </div>

      <div className="input-container">
        <input
          type={showPassword2 ? "text" : "password"}
          className="inputBox"
          placeholder={"CONFIRM YOUR PASSWORD"}
          style={{ paddingRight: "40px" }}
          name="password2"
          onChange={handleChange}
        />
        <FontAwesomeIcon
          className="eye-icon"
          icon={showPassword2 ? faEyeSlash : faEye}
          onClick={togglePasswordVisibility2}
        />
      </div>

      <input
        className="radio-as-checkbox"
        type="checkbox"
        id="termsAndConditions"
        name="termsAndConditions"
        required
      />
      <label htmlFor="termsAndConditions" style={{ marginLeft: "5px" }}>
        <a
          className="desktop-only link glowing-underline"
          href="https://www.qrmezy.com/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          I agree to the terms and conditions of QRMezy.com
        </a>
      </label>

      <SpacerDiv rem={0.5} />
      <div className="flex-container column gap">
        <button className="btn-primary" type="submit">
          REGISTER
        </button>
        <button
          className="btn-primary"
          type="button"
          onClick={() => navigateTo("/")}
        >
          CANCEL{" "}
        </button>
      </div>

      {/*<div className="col-md-6 offset-md-3" style={{marginTop: 50}}>*/}
      {/*    <button className="fb btn w-100" onClick={handleFacebookLogin}>*/}
      {/*        <FontAwesomeIcon icon={faFacebook} /> Sign up with Facebook*/}
      {/*    </button>*/}

      {/*    <button className="google btn" onClick={handleGoogleLogin}>*/}
      {/*        <FontAwesomeIcon icon={faGoogle} /> Sign up with Google+*/}
      {/*    </button>*/}

      {/*    <button className="microsoft btn" onClick={handleMicrosoftLogin}>*/}
      {/*        <FontAwesomeIcon icon={faWindows} /> Sign up with Microsoft*/}
      {/*    </button>*/}
      {/*</div>*/}
    </form>
  );
}
