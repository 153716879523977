import React from 'react';
import backgroundImage from "../Images/TPLogoHighRes.png";

const Bluey = ({ className = '' }) => {

    return (
        <div
            className={className}
            style={{
                position: 'absolute',
                top: 100,
                right: 0,
                width: '100%',
                // Allow for the top 100px so that it doesn't extend the page height beyond the viewport
                height: 'calc(100vh - 100px)', 
                backgroundImage: `url(${backgroundImage})`,
                opacity: 0.3,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right center',
                // Allow for the top 100px so that it doesn't extend the page height beyond the viewport
                backgroundSize: 'auto calc(100vh - 100px)',
                // padding: 50,
                zIndex: 0
            }}
            ></div>
        // </div>
    );
};

export default Bluey;

// =============================== To Do =================================== //



// =============================== Bugs =================================== //
