import React, { useState, useEffect } from 'react';
import { TextBox, SpacerDiv, Alert, Camera } from 'Components';
import useFetchApi from "Services/FetchApi";
import { useUserDeets } from "CustomHooks";

import '../Styles/diary.css';

interface DiaryProps {}

interface DiaryEntry {
  text: string;
  image?: string;
  date?: string;
}



const Diary: React.FC<DiaryProps> = () => {
  const fetchApi = useFetchApi();
  const user = useUserDeets();
  const [diaryEntry, setDiaryEntry] = useState<DiaryEntry>({ text: '', image: '' });
  const [historicDiaryEntries, setHistoricDiaryEntries] = useState<DiaryEntry[]>([]);
  const [showOldEntries, setShowOldEntries] = useState<boolean>(false);
  const [alert, setAlert] = useState<{ msg: string; msgCode: 4 | 3 | 2 | 1 }>({ msg: '', msgCode: 1 });
  const [isHiding, setIsHiding] = useState<boolean>(false);
  const [isText, setIsText] = useState<boolean>(false);
  const [capturedImage, setCapturedImage] = useState<string>('');
  
  useEffect(() => {
    if (capturedImage) {
      console.log('Captured Image:', capturedImage);
    }
  }, [capturedImage]);
  

  const handleSaveClick = async () => {
    try {
      await postDiaryEntry(diaryEntry);
      setDiaryEntry({ text: '', image: '' });
      setIsText(false);
      setAlert({ msg: 'Diary entry saved successfully!', msgCode: 1 });

      setTimeout(() => {
        setAlert({ msg: '', msgCode: 1 });
      }, 2000);
    } catch (error) {
      setAlert({ msg: 'Failed to save diary entry.', msgCode: 4 });

      setTimeout(() => {
        setAlert({ msg: '', msgCode: 1 });
      }, 2000);
    }
  };


  const handleClearTextClick = () => {
    setIsText(false);
    setDiaryEntry((prevEntry) => ({ ...prevEntry, text: '' }));
  };

  // Adjust the handleTextBoxChange to receive and pass the string value directly
  const handleTextBoxChange = (value: string) => {
    setDiaryEntry({ ...diaryEntry, text: value });
    setIsText(value.trim() !== ''); // If the text box is empty, hide the buttons
  };

  const handlePreviousEntriesClick = () => {
    if (showOldEntries) {
      setIsHiding(true);
      setTimeout(() => {
        setShowOldEntries(false);
        setIsHiding(false);
      }, 500); 
    } else {
      setShowOldEntries(true);
    }
  };

  /* eslint-disable-next-line no-unused-vars */
  const postDiaryEntry = (entry: DiaryEntry) => {
    // TO DO: Post the diary entry to the API
    // return new Promise<void>((resolve) => {
    //   setTimeout(() => {
    //     resolve();
    //   }, 1000);
    // });

    fetchApi("student/diary_entry", { entry, roleId: user.roleId })
        .then((response) => {

          switch (true) {
              // @ts-ignore
            case response.status >= 200 && response.status < 300:
              // @ts-ignore
              fetchHistoricDiaryEntries();
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          console.error("Fetch failed or fetch function is unreachable:", error);
        });

  };

  const fetchHistoricDiaryEntries = () => {

    // const historicEntries: DiaryEntry[] = [
    //   { date: '2024-08-16', text: 'This is the first previous diary entry.' },
    //   { date: '2024-08-15', text: 'This is the second previous diary entry.' },
    //   { date: undefined, text: 'This entry has an invalid date.' },
    // ];

    fetchApi("student/diary_history", { roleId: user.roleId })
        .then((response) => {

          switch (true) {
            // @ts-ignore
            case response.status >= 200 && response.status < 300:
              // @ts-ignore
              setHistoricDiaryEntries(response.responseData.diary_history);
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          console.error("Fetch failed or fetch function is unreachable:", error);
        });

    // const validEntries = historicEntries.filter(
    //   (entry) => entry.date && !isNaN(new Date(entry.date).getTime())
    // );
    //
    // setHistoricDiaryEntries(validEntries);
  };

  useEffect(() => {
    fetchHistoricDiaryEntries();
  }, []);

  return (
    <div className='diary-container'>
      <SpacerDiv rem={4} />
      <div className='new-diary-entry'>
        <div className='divider' />
        <h3>CREATE A DIARY ENTRY</h3>
        <div className='divider' />
        <TextBox
          questionLength={1000}
          value={diaryEntry.text}
          onChange={handleTextBoxChange} 
        />

        <div className={`btn-container ${isText ? 'show' : 'hide'}`}>
          <button className='btn-primary' onClick={handleSaveClick}>
            SAVE
          </button>
          <button className='btn-primary' onClick={handleClearTextClick}>
            CLEAR
          </button>
        </div>
        <Camera onCapture={(imageSrc) => {setCapturedImage(imageSrc)}} />

        {alert.msg && <Alert msg={alert.msg} msgCode={alert.msgCode} />}

        <SpacerDiv rem={1} />
        {historicDiaryEntries.length > 0 && (
          <button className='btn-text-only' onClick={handlePreviousEntriesClick}>
            {showOldEntries ? 'HIDE PREVIOUS ENTRIES' : 'SHOW PREVIOUS DIARY ENTRIES'}
          </button>
        )}
      </div>

      {showOldEntries && (
        <>
          <SpacerDiv rem={1} />
          <div className='historic-diary-entry'>
            {historicDiaryEntries
              .sort((a, b) => {
                const dateA = a.date ? new Date(a.date).getTime() : 0;
                const dateB = b.date ? new Date(b.date).getTime() : 0;
                return dateB - dateA;
              })
              .map((entry, index) => (
                  <div
                      key={index}
                      className={`historic-entry ${isHiding ? 'hide' : 'show'}`}
                      style={{animationDelay: `${index * 100}ms`}}
                  >
                    <div className='divider grey'/>
                    {/*<h4>{entry.date}</h4>*/}
                    <h4>{entry.date ? new Date(entry.date).toLocaleDateString('en-GB') : 'Invalid Date'}</h4>

                    {/*<h4>{formatDate(entry.date)}</h4> /!* Updated to use formatted date *!/*/}
                    <div className='divider grey'/>
                    <TextBox
                        questionLength={1000}
                        value={entry.text}
                        onChange={(value) =>
                            setHistoricDiaryEntries((prevEntries) =>
                                prevEntries.map((prevEntry, i) =>
                                    i === index ? {...prevEntry, text: value} : prevEntry
                                )
                            )
                        }
                    />
                  </div>
              ))}
          </div>
          <SpacerDiv rem={4} />
        </>
      )}
    </div>
  );
};

export default Diary;