import React from 'react';
import { sign } from 'Images';
import '../Styles/error.scss';
import { useNavigate } from 'react-router-dom';

interface ErrorProps {};

const Error: React.FC<ErrorProps> = () => {
    const navigate = useNavigate();

    if (window.innerWidth < 900) {
      return (
        <div className='error-page-container'>
          <div className='sign-container'>
            <img src={sign} alt='Road Sign' className='sign' />
            <div className='error-message'>ERROR 404</div>
            <button className='home-button' onClick={() =>navigate('/')}>HOME</button>
            <button className='back-button' onClick={() =>navigate(-1)}>GO BACK</button>
          </div>
        </div>
      )
    } else {
      return (
        <div className='error-page-container'>
          <div className='sign-container'>
            <img src={sign} alt='Road Sign' className='sign' />
            <div className='error-message'>ERROR 404</div>
            <button className='home-button' onClick={() =>navigate('/')}>HOME</button>
            <button className='back-button' onClick={() =>navigate(-1)}>GO BACK</button>
          </div>
        </div>
      )
    }

};

export default Error;
